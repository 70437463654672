import { useApi } from '~/plugins/api';
import { loginRouter } from '~/assets/helpers/routers';
import { removeCookie, setCookie } from '~/assets/utils/cookie';
import { useKeycloak } from '~/assets/utils/useKeycloak';

export enum AccessRole {
  Admin = 'admin',
  Auditor = 'auditor',
  Supervisor = 'supervisor',
  SeniorEditor = 'senioreditor',
  Support = 'support',
  DataAdmin = 'data admin',
  Editor = 'editor',
  Merch = 'merch',
  Operator = 'operator',
  Test = 'test',
  Billing = 'billing'
}

interface User {
  id: number,
  name: string,
  username: string,
  email: string,
  time_zone: string,
  created_at: string,
  updated_at: string,
  is_disabled: boolean,
  permissions: string[],
  is_system: boolean,
  new_token?: string,
  role: AccessRole,
  brand_owner_id?: string,
  need_reset_password: boolean,
}

const TokenPrefix = 'Bearer ';
export const PingCookie = 'token';
export const InternalCookie = 'auth._token.local';
const AuthToken = 'auth-token';

export const useUserStore = defineStore('userStore',
  () => {
    const token = ref('');

    const user = ref<User>();

    const loggedIn = computed(() => !!token.value);

    function setUserToken(newToken: string) {
      token.value = TokenPrefix + newToken;
      setCookie(InternalCookie, token.value);
      setCookie(AuthToken, newToken);
    }

    const { authSSO: _authSSO, authLogoutSSO, ssoLoading, sso, updateTokenSSO } = useKeycloak({
      setUserToken,
      tokenSSO: getCleanToken()
    });

    function authSSO() {
      _authSSO(location.origin + getRedirectUrl());
    }

    function getUserToken() {
      return token.value;
    }

    function getCleanToken() {
      return getUserToken().replace(TokenPrefix, '');
    }

    async function fetchUser() {
      if (token.value) {
        user.value = await useApi().auth.me();
      }
    }

    const route = useRoute();

    function getRedirectUrl() {
      const { source_uri } = route.query || {};
      const redirectBySourceUri = fromArray(source_uri);
      const redirectUri = redirectBySourceUri || route.path;
      return redirectUri.includes('login') ? '/visits' : redirectUri;
    }

    async function logout() {
      token.value = '';
      user.value = null;

      await authLogoutSSO();

      removeCookie(PingCookie);
      removeCookie(InternalCookie);
      removeCookie(AuthToken);

      const name = getRedirectUrl();

      if (!name.includes('login')) {
        navigateTo(loginRouter(name));
      }
    }

    async function relogin() {
      if (sso.value) authSSO();
      else {
        token.value = '';

        const name = getRedirectUrl();

        if (!name.includes('login')) {
          navigateTo(loginRouter(name));
        }
      }
    }

    return {
      sso,
      token,
      authSSO,
      setUserToken,
      getUserToken,
      getCleanToken,
      updateTokenSSO,
      fetchUser,
      logout,
      relogin,
      user,
      loggedIn,
      ssoLoading,
    };
  }, {
    persist: {
      storage: localStorage,
      omit: ['ssoLoading', 'ssoInitPromise'],
    }
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
