import { MetricsType, MetricsLocale } from '~/components/metrics/MetricsType';

export default {
  metrics: {
    schema: {
      list: {
        pk: {
          type: 'string',
          locale: 'global.id',
        },
        external_id: {
          type: 'string',
          locale: 'global.external_id',
        },
        name: {
          type: 'string',
          locale: 'global.name',
        },
        type: 'string',
        params: 'string',
        is_disabled: {
          type: 'int',
          locale: 'global.is_active',
        },
      },
      create: {
        is_disabled: {
          type: 'bool',
          locale: 'global.is_disabled',
        },
        type: {
          required: true,
          type: 'list',
          default: MetricsType.SOS,
          options: [
            MetricsType.SOS,
            MetricsType.BrandBlock,
            MetricsType.PriceRange,
            MetricsType.DisplayWidth,
            MetricsType.Planogramm,
            MetricsType.POSM,
          ].map(id => ({ id, locale: MetricsLocale[id] })),
        },
        name: {
          type: 'string',
          locale: 'global.name',
        },
        external_id: {
          type: 'string',
          locale: 'global.external_id',
        },
        params: 'other',
      },
    },
  },
};
