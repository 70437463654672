
// @ts-nocheck


export const localeCodes =  [
  "en",
  "ru",
  "es",
  "tr",
  "pt",
  "dev"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46ts_3baa3fc9",
      load: () => import("#nuxt-i18n/3baa3fc9" /* webpackChunkName: "locale_en_46ts_3baa3fc9" */),
      cache: false
    }
  ],
  ru: [
    {
      key: "locale_ru_46ts_75bdb733",
      load: () => import("#nuxt-i18n/75bdb733" /* webpackChunkName: "locale_ru_46ts_75bdb733" */),
      cache: false
    }
  ],
  es: [
    {
      key: "locale_es_46ts_42fd1d5e",
      load: () => import("#nuxt-i18n/42fd1d5e" /* webpackChunkName: "locale_es_46ts_42fd1d5e" */),
      cache: false
    }
  ],
  tr: [
    {
      key: "locale_tr_46ts_21911c71",
      load: () => import("#nuxt-i18n/21911c71" /* webpackChunkName: "locale_tr_46ts_21911c71" */),
      cache: false
    }
  ],
  pt: [
    {
      key: "locale_pt_46ts_dd1a4e49",
      load: () => import("#nuxt-i18n/dd1a4e49" /* webpackChunkName: "locale_pt_46ts_dd1a4e49" */),
      cache: false
    }
  ],
  dev: [
    {
      key: "locale_dev_46ts_02cb641d",
      load: () => import("#nuxt-i18n/02cb641d" /* webpackChunkName: "locale_dev_46ts_02cb641d" */),
      cache: false
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/ee4e6a16" /* webpackChunkName: "config_i18n_46config_46ts_ee4e6a16" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      language: "en",
      cache: true,
      files: [
        "/app/i18n/loc/en.ts"
      ]
    },
    {
      code: "ru",
      language: "ru",
      cache: true,
      files: [
        "/app/i18n/loc/ru.ts"
      ]
    },
    {
      code: "es",
      language: "es",
      cache: true,
      files: [
        "/app/i18n/loc/es.ts"
      ]
    },
    {
      code: "tr",
      language: "tr",
      cache: true,
      files: [
        "/app/i18n/loc/tr.ts"
      ]
    },
    {
      code: "pt",
      language: "pt",
      cache: true,
      files: [
        "/app/i18n/loc/pt.ts"
      ]
    },
    {
      code: "dev",
      language: "dev",
      files: [
        "/app/i18n/loc/dev.ts"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "loc",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "lang",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    language: "en",
    cache: true,
    files: [
      {
        path: "/app/i18n/loc/en.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "ru",
    language: "ru",
    cache: true,
    files: [
      {
        path: "/app/i18n/loc/ru.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    language: "es",
    cache: true,
    files: [
      {
        path: "/app/i18n/loc/es.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "tr",
    language: "tr",
    cache: true,
    files: [
      {
        path: "/app/i18n/loc/tr.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "pt",
    language: "pt",
    cache: true,
    files: [
      {
        path: "/app/i18n/loc/pt.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "dev",
    language: "dev",
    files: [
      {
        path: "/app/i18n/loc/dev.ts",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/