import { syncRef } from '@vueuse/core';

export const locale = ref<string>();

export default defineNuxtPlugin((nuxtApp) => {
  syncRef(nuxtApp.$i18n.locale, locale, { direction: 'ltr' });

  const { query } = useRoute();
  const queryLang = (query.locale || query.lang) as string;
  if (queryLang) {
    nuxtApp.$i18n.setLocale(queryLang);
  }
});
