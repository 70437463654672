import { watchOnce, useLocalStorage } from '@vueuse/core';
import Keycloak from 'keycloak-js';

import { useApi } from '~/plugins/api';
import { RouteName } from '~/middleware/access.global';

const token = useLocalStorage('kc-token', '');
const refreshToken = useLocalStorage('kc-refresh-token', '');
const idToken = useLocalStorage('kc-id-token', '');

export function useKeycloak({ setUserToken, tokenSSO }: { setUserToken: (t: string) => void, tokenSSO: Ref<string> }) {
  const ssoLoading = ref(false);

  const config = ref();
  const sso = computed(() => Boolean(config.value?.sso));

  async function getConfig() {
    if (!config.value) {
      const authConfig = await useApi().auth.config();
      config.value = { oidcProvider: authConfig.realm_url, clientId: authConfig.web_client_id, sso: authConfig.sso };
    }
    return config.value;
  }

  const route = useRoute();

  let keycloak: Keycloak;

  ssoLoading.value = true;

  function updateTokens(){
    setUserToken(keycloak.token);
    token.value = keycloak.token;
    refreshToken.value = keycloak.refreshToken;
    idToken.value = keycloak.idToken;
  }

  getConfig().then(() => {
    if (!sso.value) {
      ssoLoading.value = false;
      return;
    }

    keycloak = new Keycloak(config.value);

    keycloak.init({
      checkLoginIframe: false,
      // checkLoginIframeInterval: 60,
      // flow: 'implicit',
      // flow: 'standard',
      responseMode: 'query',
      silentCheckSsoFallback: true,
      onLoad: 'login-required',
      ...(token.value ? {
        token: token.value,
        refreshToken: refreshToken.value,
        idToken: idToken.value,
      } : {}),
    }).then((authenticated) => {
      if (authenticated) {
        if (tokenSSO.value !== keycloak.token) updateTokens();
        // TODO source_uri
        if (route.name === RouteName.Login) navigateTo('/visits');
      }
    }).finally(() => {
      ssoLoading.value = false;
    });
  });

  function authSSO() {
    keycloak?.login();
  }

  function authLogoutSSO() {
    return keycloak?.logout();
  }

  async function updateTokenSSO() {
    if (ssoLoading.value) await awaitSSO();

    const refreshed = await keycloak?.updateToken(30);

    if (refreshed) updateTokens();
  }

  async function awaitSSO() {
    return new Promise((resolve) => watchOnce(ssoLoading, resolve));
  }

  return {
    sso,
    authSSO,
    ssoLoading,
    authLogoutSSO,
    updateTokenSSO,
  };
}
