import type { Breadcrumb } from '~/stores/appStore';
import recentPages from '../components/header/RecentPages.vue';

interface RecentPage {
  name: string;
  route: string;
  breadcrumbs?: Breadcrumb[],
  timestamp: object,
}

export const useRecentPagesStore = defineStore('recentPages',
  () => {
    const items = ref<RecentPage[]>([]);

    function addItem(item: RecentPage) {
      const newItems = items.value.filter(el => el.route !== item.route);

      if (newItems.length >= 10) {
        newItems.length = 9;
      }

      newItems.unshift(item);

      items.value = newItems;
    }

    function updateTitle(title) {
      if (items.value[0]) {
        items.value[0].name = title;
      }
    }

    function clear() {
      items.value = [];
    }

    return {
      items,
      addItem,
      updateTitle,
      clear,
    };
  }, {
    persist: {
      storage: localStorage,
    }
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(recentPages, import.meta.hot))
}
