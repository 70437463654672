import { getQuery } from '~/assets/utils/getQuery';

export function getPath() {
  return window.location.origin + window.location.pathname;
}

export function getVisitUrl(id, photo?) {
  const query = getQuery({ photo });
  return `/visits/${id}` + query;
}

export function getReportUrl(model) {
  return `/reports/${model.pk}`;
}

export function getReportSearchUrlByName(model) {
  const query = getQuery({ search: model.name });
  return '/reports' + query;
}

export function getStoreUrl(id: string | number) {
  return `/stores/${id}`;
}

// Users
export function getUsersPermissionsUrl(id: string | number) {
  return `/old/user/${id}/permissions-update`;
}

export function getUserTeamsUrl(id: string | number) {
  return `/old/user/${id}/teams`;
}
