export default defineNuxtPlugin((app) => {
  onNuxtReady(() => {
    const { initSettings, initBranding } = useSettingsStore();
    const { loggedIn } = useUserStore();

    if (loggedIn) {
      return initSettings();
    }
    return initBranding(true);
  });
});
