import createResources from '~/assets/api/resources';
import useApiClient from '~/assets/api/api';
import { createSharedComposable } from '@vueuse/core';

export default defineNuxtPlugin((nuxtApp) => {
    const { initBranding } = useSettingsStore();
    initBranding();
});

export const useApi = createSharedComposable(() => {
    const runtimeConfig = useRuntimeConfig().public;
    const iris = useApiClient(runtimeConfig.API_URL);
    const muse = useApiClient(runtimeConfig.MUSE_URL || (runtimeConfig.API_URL + '/md'));
    const clio = useApiClient(runtimeConfig.CLIO_URL);
    const sfaApi = useApiClient(runtimeConfig.SFA_TASKS_URL);
    const sfaApiV2 = useApiClient(runtimeConfig.SFA_TASKS_URL_V2);
    const visa = useApiClient(runtimeConfig.VISA_URL);
    const old = useApiClient('/old');

    return createResources({ iris, muse, clio, sfaApi, sfaApiV2, visa, old });
});

export function defaultExtractor(res: any) {
    return res.data || res.items || [];
}

export function useApiOptions(resource: string, suggest = 'suggest', transform = defaultExtractor) {
    // TODO HOT HOT HOT FIX
    let query = '';
    let data = [];
    return async(args = {}): Promise<any[]> => {
        const currentQuery = JSON.stringify(args);
        query = currentQuery;
        const res = await useApi()[resource][suggest](args);
        if (currentQuery === query) {
            data = transform(res)
        }
        return data;
    };
}
