import payload_plugin_bbFPbIrjp_Z3u8WRbbLNshLCNyS5k2BPVwVrz6g6omQ from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Bwbe9WtIEQTR32nMhaMZ1TiZFZGJ5P01j8xy_gpEmrE from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fZDIRw26dITRAjR7nN5h1HV4xeA_a9aLJOw1TGFwmwI from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_beww0F1e6oIYiLWU_yGFesSaRBz3J7s8VzMIqJupIxI from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MGhiEhzKN7aYkYqT3p_niJV_k6zBpKYP0PvkiasbNzA from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_oBKjm2RKLdneBKjr118elesd5X2IW4tyD9XP3He7EjA from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LFyLS6a2pLAeVT9ZvjbIILGA_6wBFVdjVzgn9XEvNBU from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dOGXB3j8mqRKA7dEozJk4Xbkpzwo_QyOHyvI9YmQ_i8 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_MPVlKfNy3FLxFMJcnxxcl3Pf6OfyF3fuuclHnO_mHbA from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_NU95jOdXTEpaw4Tpiv03hWRljd251dECBgPrZkh_Muo from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_FO6wqAjRCNlJgf_2S4Mdyiwz1zhPItuoC5RkeLG6fd4 from "/app/node_modules/.pnpm/@sentry+nuxt@9.8.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@_cfc7037f16d649ff7b734081bce902e0/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/app/.nuxt/sentry-client-config.mjs";
import plugin_px4V_TFN1ondejo7hwiCe1OB8UCmdgxNkEW6D7HoXHY from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescri_14f79b278ded115d66297c1ae897df30/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4 from "/app/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/app/.nuxt/unocss.mjs";
import switch_locale_path_ssr_UPRIntkS_m_sEAhFzqX_NOnTCd7uJ7PC6nOdXSjyUQk from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.4_@vue+compiler-dom@3.5.13_eslint@9.17.0_jiti@2.4.2__magicast@0.3.5_ro_76d3d225725a2a43f46d8eb79b354466/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_YURxyj0YYgaVdDurbLfnMOHzKX4yZyKwcHCb0D3ZGUM from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.4_@vue+compiler-dom@3.5.13_eslint@9.17.0_jiti@2.4.2__magicast@0.3.5_ro_76d3d225725a2a43f46d8eb79b354466/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_cb21564lGQ54dx4XvuUyIjrmmheqHOLzvuGWURSmJc4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.4_@vue+compiler-dom@3.5.13_eslint@9.17.0_jiti@2.4.2__magicast@0.3.5_ro_76d3d225725a2a43f46d8eb79b354466/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/app/plugins/api.ts";
import i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c from "/app/plugins/i18n.ts";
import localStorage_OTJxYs6tb6A_0OpC6hxrNecwx0BKdS8ApQ9Tvzg8jsY from "/app/plugins/localStorage.ts";
import metrics_3FWXG3mIr9NvVgDs2g9wJaLB7ubiqmC5xQqWUk0T0pM from "/app/plugins/metrics.ts";
import ssg_detect_OtegEffUWWbnxSJJMnKnyfamNvlEOtnIJ1mct3uH3OM from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.4_@vue+compiler-dom@3.5.13_eslint@9.17.0_jiti@2.4.2__magicast@0.3.5_ro_76d3d225725a2a43f46d8eb79b354466/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_bbFPbIrjp_Z3u8WRbbLNshLCNyS5k2BPVwVrz6g6omQ,
  revive_payload_client_Bwbe9WtIEQTR32nMhaMZ1TiZFZGJ5P01j8xy_gpEmrE,
  unhead_fZDIRw26dITRAjR7nN5h1HV4xeA_a9aLJOw1TGFwmwI,
  router_beww0F1e6oIYiLWU_yGFesSaRBz3J7s8VzMIqJupIxI,
  payload_client_MGhiEhzKN7aYkYqT3p_niJV_k6zBpKYP0PvkiasbNzA,
  navigation_repaint_client_oBKjm2RKLdneBKjr118elesd5X2IW4tyD9XP3He7EjA,
  check_outdated_build_client_LFyLS6a2pLAeVT9ZvjbIILGA_6wBFVdjVzgn9XEvNBU,
  chunk_reload_client_dOGXB3j8mqRKA7dEozJk4Xbkpzwo_QyOHyvI9YmQ_i8,
  plugin_vue3_MPVlKfNy3FLxFMJcnxxcl3Pf6OfyF3fuuclHnO_mHbA,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_NU95jOdXTEpaw4Tpiv03hWRljd251dECBgPrZkh_Muo,
  sentry_client_FO6wqAjRCNlJgf_2S4Mdyiwz1zhPItuoC5RkeLG6fd4,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_px4V_TFN1ondejo7hwiCe1OB8UCmdgxNkEW6D7HoXHY,
  plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  switch_locale_path_ssr_UPRIntkS_m_sEAhFzqX_NOnTCd7uJ7PC6nOdXSjyUQk,
  route_locale_detect_YURxyj0YYgaVdDurbLfnMOHzKX4yZyKwcHCb0D3ZGUM,
  i18n_cb21564lGQ54dx4XvuUyIjrmmheqHOLzvuGWURSmJc4,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c,
  localStorage_OTJxYs6tb6A_0OpC6hxrNecwx0BKdS8ApQ9Tvzg8jsY,
  metrics_3FWXG3mIr9NvVgDs2g9wJaLB7ubiqmC5xQqWUk0T0pM,
  ssg_detect_OtegEffUWWbnxSJJMnKnyfamNvlEOtnIJ1mct3uH3OM
]