import { createFetch } from '@vueuse/core';

export function createFetchClient(baseUrl: string) {
  return createFetch({
    baseUrl,
    options: {
      updateDataOnError: true,
      async beforeFetch({ options }) {
        const { loggedIn, getUserToken, updateTokenSSO } = useUserStore();

        if (loggedIn) {
          await updateTokenSSO();

          options.headers = {
            ...options.headers,
            Authorization: getUserToken(),
          };
        }
      },
      onFetchError(ctx) {
        if (ctx.response?.status === 401 || ctx.data?.message === 'Not authorized') {
          useUserStore().relogin();
          return ctx;
        }

        if (ctx.response?.status === 204) {
          return { error: null };
        }

        if (ctx.response?.status === 500) {
          return {
            error: true,
            data: {
              message: ctx.response.statusText,
            },
          };
        }

        useAppStore().setLoading(false);
        console.error(ctx.error);

        return ctx;
      },
    },
  });
}
