import validate from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/pages/runtime/validate.js";
import access_45global from "/app/middleware/access.global.ts";
import meta_45global from "/app/middleware/meta.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.17.0_jiti@2.4_873caf5c6be719970612616d5fe7b6be/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  access_45global,
  meta_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "recent-pages": () => import("/app/middleware/recent-pages.ts")
}