import { loginRouter } from '~/assets/helpers/routers';
import { getQuery } from '~/assets/utils/getQuery';
import { useAccess } from '~/assets/mixins/accessMixin';
import { getVisitUrl } from '~/assets/utils/url';
import { PingCookie, InternalCookie } from '~/stores/userStore';
import { getCookie, setCookie } from '~/assets/utils/cookie';

function redirectOnVisit(name: string) {
  const ids = name.replace('#', '').split('/');
  console.log('navigateTo', 'redirectOnVisit');
  return navigateTo(getVisitUrl(ids[0], getQuery({ photo: ids[1] })));
}

const error403 = { statusCode: 403, message: 'Permission denied' };

export const RouteName = {
  Login: 'LoginPage',
};

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { user, loggedIn, fetchUser, setUserToken, getUserToken } = useUserStore();

  const routeName = to.name || to.path.replace('/', '');

  if (!loggedIn) {
    if (['logout', 'password-recovery', 'password-update'].includes(routeName)) {
      return;
    }

    const token = getCookie(PingCookie);
    if (token) {
      setUserToken(token);
    } else {
      if (routeName === RouteName.Login) return;
      return navigateTo(loginRouter(to.fullPath));
    }
  }

  if (!getCookie(InternalCookie)) {
    setCookie(InternalCookie, getUserToken());
  }

  const fetcher = fetchUser();
  if (!user) await fetcher;

  if ((routeName === 'index' && !to.hash) || routeName === RouteName.Login) return navigateTo('/visits');

  const id = to.params.id || to.hash || '';
  if (['visits-id', 'visit-id', 'old-visit', 'visits'].includes(routeName) && id.includes('#') && !id.includes('=')) return redirectOnVisit(id);

  if ((routeName.startsWith('visit/') || routeName === 'index') && !id.includes('=')) return redirectOnVisit(to.hash || routeName.replace('visit/', ''));

  const { canByRole, canByPermission } = useAccess();

  const meta = Array.isArray(to.meta) ? to.meta[0] : to.meta;
  if (meta) {
    if (meta.access && !canByPermission(meta.access)) abortNavigation(error403);
    if (meta.accessRoles && !canByRole(meta.accessRoles)) abortNavigation(error403);
  }
  if (routeName === 'old-app-page-id' && to.params.id === 'login' && to.params.page === 'site') return navigateTo({ name: RouteName.Login });

  const settingsStore = useSettingsStore();
  if ((routeName === 'routes' && !settingsStore.showRoutes) ||
    (routeName === 'sfa-tasks' && !settingsStore.showSfaTasks) ||
    (routeName === 'planogramm' && !settingsStore.showPlanogramm)) {
    abortNavigation(error403);
  }
});
