import { filterAreas, treeReFormatter } from '~/components/areas/areasHelpers';
import useApiClient, { Method, ParseMethod } from '~/assets/api/api';

import mocks from './mocks';
import { locale } from '~/plugins/i18n';

type ApiClient = ReturnType<typeof useApiClient>;

export function getFormData(params: { [key: string]: string | any }) {
  return Object.entries(params).reduce((data, [key, value]) => {
    data.append(key, value);
    return data;
  }, new FormData());
}

const oldFilterFetch = (type: string, old: ApiClient) => {
  return async (query) => {
    if (!query.query) return new Promise(resolve => resolve({ data: [] }));

    const res = await old._request('ajax/visit/filter-fetch', Method.POST, getFormData({
      filter_type: type,
      filter_query: query.query
    }));
    return { data: res.results };
  };
};

async function museSuggestCrutch(muse, resourcePath, query, idKey, textKey, isDisplay = false) {
  const resp = await muse._fetch(`${resourcePath}/suggest`, { query });
  if (query.query && (!resp.data?.length || resp.data?.every(e => e[idKey] !== query.query))) {
    try {
      const respById = await muse._fetch(`${resourcePath}/${query.query}${isDisplay ? '/display' : ''}`);
      if (respById) {
        resp.data.push({ id: respById[idKey], text: respById[textKey] });
      }
    } catch (e) {
      //
    }
  }
  return resp;
}

const saveStatusInRequest = {
  onResponse(r) {
    r.response._data.status = r.response.status;
  }
};

export default ({ iris, muse, clio, sfaApi, sfaApiV2, visa, old }: {
  iris: ApiClient,
  muse: ApiClient,
  clio: ApiClient,
  sfaApi: ApiClient,
  sfaApiV2: ApiClient,
  visa: ApiClient,
  old: ApiClient
}) => {
  return {
    iris,
    muse,
    clio,
    old,
    auth: {
      login: credentials => iris._fetch('auth/login_portal', {
        method: Method.POST,
        body: credentials,
        immediate: false
      }),
      me: () => iris._fetch('auth/me'),
      config: () => iris._fetch('auth/configuration'),
      recovery: login => old._request('site/req-password-reset', Method.POST, getFormData({ 'PasswordResetRequestForm[name]': login })),
    },
    versions: () => iris._fetch('services/versions'),
    scene_types: iris.createResource('scene_types'),
    salesLocations: muse.createResource('scene_types'),
    scene_groups: muse.createResource('scene_groups'),
    countries: iris.createResource('countries'),
    product_category: muse.createResource('product_category'),
    brands: muse.createResource('brand'),
    brand_owner: muse.createResource('brand_owner'),
    // klass: muse.createResource('klass'),
    regions: muse.createResource('regions'),
    retailers: muse.createResource('retailers'),
    assortment_matrices: muse.createResource('assortment_matrices'),
    assortment_matrices_types: muse.createResource('assortment_matrices_types'),
    assortment_products: {
      display_list: (filter) => {
        const { assortment_id, ...query } = filter;
        return muse._fetch(`assortment_matrices/${assortment_id}/assortment/display`, { query });
      },
      delete: (assortment_id, id) => muse._fetch(`assortment_matrices/${assortment_id}/assortment/${id}`, { method: Method.DELETE }),
      get: (assortment_id, id) => muse._fetch(`assortment_matrices/${assortment_id}/assortment/${id}`),
      update: (assortment_id, id, body) => muse._fetch(`assortment_matrices/${assortment_id}/assortment/${id}`, { method: Method.PUT, body }),
    },
    users: muse.createResource('users', (_req, resourcePath, apiFetch) => {
      return {
        display_list: query => apiFetch(`${resourcePath}/display`, { query: { ...query, no_total: true } }),
        suggest_crutch_name: ({ query, ...filters }) => apiFetch(`${resourcePath}/display`, { query: { ...filters, name: query } }),
        suggest_crutch_username: ({ query, ...filters }) => apiFetch(`${resourcePath}/display`, { query: { ...filters, username: query } }),
      };
    }),
    roles: muse.createResource('roles'),
    metrics: muse.createResource('metrics', (_req, _resourcePath) => {
      const schema = () => {
        return new Promise((resolve) => {
          resolve(mocks.metrics.schema);
        });
      };
      return { schema };
    }),
    photos: iris.createResource('photos', (req, resourcePath, apiFetch) => {
      const photo_options_suggest = (query = {}) => apiFetch(`${resourcePath}/photo_options/suggest`, {
        query: { ...query, lang: locale.value }
      });
      const realogram = (photoId: string) => apiFetch(photoId.includes('scenes') ? `visits/${photoId}/realogram` : `ui/${resourcePath}/${photoId}/realogramm_v2`);
      const realogramv2 = (photoId: string) => apiFetch(`ui/${resourcePath}/${photoId}/realogramm_v2`);
      const sceneRealogram = (visitId: string, sceneId: string) => apiFetch(`visits/${visitId}/scenes/${sceneId}/realogram_v2`);
      const rr = (photoId: string) => apiFetch(`${resourcePath}/${photoId}/realogramm/raw`);
      const realogramm_products = (photoId, params) => req(`${resourcePath}/${photoId}/realogramm_products`, Method.PUT, params);
      return { photo_options_suggest, realogram, realogramv2, sceneRealogram, rr, realogramm_products };
    }),
    products: muse.createResource('products', (_req, resPath) => ({
      suggest: query => museSuggestCrutch(muse, resPath, query, 'id', 'product_name', true),
    })),
    visits: iris.createResource('visits', (req, resourcePath, apiFetch) => {
      const getStatus = visitId => apiFetch(`${resourcePath}/${visitId}/status`, saveStatusInRequest);
      const getWidget = (visit_id, type, query) => apiFetch(`ui/visits/${visit_id}/widgets/${type}`, { query });
      const widgetViews = (visit_id, type, query) => apiFetch(`ui/visits/${visit_id}/widgets/${type}/views`, { query });
      const widgetReferences = (visit_id, type, query) => apiFetch(`visits/${visit_id}/widgets/${type}/references`, { query });
      const suggest_ext_id = oldFilterFetch('external_visit', old);
      const suggest_photo_id = oldFilterFetch('photo', old);
      const updateSceneType = (visitId, sceneId, body) => apiFetch(`${resourcePath}/${visitId}/scenes/${sceneId}`, { method: Method.PUT, body });
      const updateStatus = (visitId, query) => apiFetch(`${resourcePath}/${visitId}/status`, { method: Method.PUT, query });
      const recalcVisit = (visitId, query) => apiFetch(`${resourcePath}/${visitId}/recalc`, { method: Method.POST, query });
      const assortment = photoId => apiFetch(`ui/${resourcePath}/${photoId}/assortment`);
      const changeStatusMissAssort = (visitId, id, state) => apiFetch(`${resourcePath}/${visitId}/assortment_miss/products/${id}/change_assortment_miss_state/${state}`, { method: Method.POST });

      const availabilityCorrection = (id, body) => apiFetch(`${resourcePath}/${id}/availability_correction`, { method: Method.POST, body });
      const deleteAvailabilityCorrection = (id, photoId, faceId) => apiFetch(`${resourcePath}/${id}/availability_correction/photos/${photoId}/faces/${faceId}`, { method: Method.DELETE });

      return {
        getStatus,
        getWidget,
        widgetViews,
        widgetReferences,
        suggest_ext_id,
        suggest_photo_id,
        updateSceneType,
        updateStatus,
        recalcVisit,
        assortment,
        changeStatusMissAssort,
        availabilityCorrection,
        deleteAvailabilityCorrection,
      };
    }),
    billing: iris.createResource('billing', (req, resourcePath, apiFetch) => {
      const stats = query => apiFetch(`${resourcePath}/stats`, { query });
      return { stats };
    }),
    reports: clio.createResource('reports', (req, resourcePath, apiFetch) => {
      const info = () => apiFetch(`${resourcePath}/info`);
      const update = (id, data) => req(`${resourcePath}/${id}`, Method.PUT, {
        ...data,
        user_id: useUserStore().user.id
      });
      return { info, update };
    }),
    report_jobs: clio.createResource('report_jobs', (req, resourcePath, apiFetch) => {
      const logs = id => req(`${resourcePath}/${id}/logs`, Method.GET, null, {}, {}, ParseMethod.TEXT);
      const stop = id => apiFetch(`${resourcePath}/${id}/stop`);
      const fileHead = id => req(`${resourcePath}/${id}/file`, Method.HEAD, null, {}, {}, ParseMethod.TEXT);
      return { logs, stop, fileHead };
    }),
    import_jobs: clio.createResource('import_jobs', (req, resourcePath, apiFetch) => {
      const logs = id => req(`${resourcePath}/${id}/logs`, Method.GET, null, {}, {}, ParseMethod.TEXT);
      const restart = id => apiFetch(`${resourcePath}/${id}/restart`,);
      return { logs, restart };
    }),
    stores: iris.createResource('stores', (req, resourcePath, apiFetch) => {
      const store_types_suggest = query => apiFetch(`${resourcePath}/types/suggest`, { query });
      const create = (data, config) => muse._request(`${resourcePath}/`, Method.POST, data || {}, false, config);
      const update = (id, data) => muse._request(`${resourcePath}/${id}`, Method.PUT, data);
      const importPreview = formData => muse._request(`${resourcePath}/import/preview`, Method.POST, formData);
      const importMethod = formData => muse._request(`${resourcePath}/import`, Method.POST, formData);
      const museSuggest = query => museSuggestCrutch(muse, resourcePath, query, 'id', 'name');
      // const display_list = query => req(`${resourcePath}/display_extended`, Method.GET, false, query);
      const list = query => apiFetch(`ui/${resourcePath}/display_extended`, { query });
      const display_list = (filters) => {
        const { query, ...other } = filters;
        return muse._fetch(`${resourcePath}/display`, { query: { ...other, name: query } });
      };

      return {
        store_types_suggest,
        importPreview,
        import: importMethod,
        museSuggest,
        list,
        display_list,
        create,
        update,
      };
    }),
    tasks: muse.createResource('tasks', (_req, resourcePath, apiFetch) => {
      const schema = (id = undefined) => {
        if (id) {
          return apiFetch(`${resourcePath}/${id}/schema`);
        }
        return apiFetch(`${resourcePath}/schema`);
      };
      return { schema };
    }),
    store_segments: muse.createResource('store_segments'),
    price_standards: muse.createResource('price_standards'),
    metrics_plans: muse.createResource('metrics_plans'),
    areas: iris.createResource('areas', (_req, resourcePath, apiFetch) => {
      const mdTree = query => muse._fetch(`${resourcePath}/tree`, { query });
      const stores = (id, query) => apiFetch(`${resourcePath}/${id}/stores`, { query });
      const addStore = (id, store_id) => apiFetch(`${resourcePath}/${id}/stores/${store_id}`, { method: Method.POST });
      const removeStore = (id, store_id) => apiFetch(`${resourcePath}/${id}/stores/${store_id}`, { method: Method.DELETE });
      const importPreview = formData => muse._request(`${resourcePath}/import/preview`, Method.POST, formData);
      const importMethod = formData => muse._request(`${resourcePath}/import`, Method.POST, formData);
      const museSuggest = query => museSuggestCrutch(muse, resourcePath, query, 'pk', 'name');
      const userStoresByAreaSuggest = ({ user_id, ...query }) => muse._fetch(`${resourcePath}/user/${user_id}/stores/suggest`, { query });

      const { getCacheDataAsync } = useAreasCacheStore();
      const display_list = async (query) => {
        const resp = await getCacheDataAsync();
        const { limit, offset = 0, name } = query;
        const children = name ? treeReFormatter(filterAreas(resp.children, new RegExp(name)), query) : resp.children;
        return {
          data: children.slice(offset, offset + limit),
          total: children.length,
          storesWithoutArea: resp.stores_without_areas_count,
          usersWithoutArea: resp.users_without_areas_count,
          totalAreas: resp.areas_total,
        };
      };

      return {
        display_list,
        mdTree,
        stores,
        addStore,
        removeStore,
        importPreview,
        import: importMethod,
        museSuggest,
        userStoresByAreaSuggest
      };
    }),
    'areas-stores': muse.createResource('areas/stores', (_req, _, apiFetch) => {
      const display_list = (params) => {
        const { areaId, ...query } = params;
        return apiFetch(`areas/${areaId}/stores`, { query });
      };

      return { display_list };
    }),
    files: muse.createResource('files', (_req, resourcePath, apiFetch) => {
      const create = (file, scope) => apiFetch(`${resourcePath}/`, { method: Method.POST, body: getFormData({ file, scope }) });
      return { create };
    }),
    periods: muse.createResource('periods'),
    cities: muse.createResource('cities', () => {
      return {
        create: data => old._request('cities/add-products', Method.POST, data),
      };
    }),
    routes: muse.createResource('routes'),
    mobile_settings: muse.createResource('mobile_settings'),
    lots: {
      add: (photo, type, value) => old._request(`lot/${value ? 'add-photo' : 'remove-photo'}`, Method.POST, getFormData({
        photo,
        type
      })),
      addProducts: (photo, products: { [key: string]: string }[]) => {
        const data = new FormData();
        data.append('photo', photo);
        products.forEach((product, index) => {
          Object.entries(product).forEach(([k, v]) => {
            data.append(`products[${index}][${k}]`, v);
          });
        });
        data.append('new_prod[id]', products[0].prod_id);
        return old._request('lot/add-products', Method.POST, data);
      },
    },
    portal_settings: {
      list: () => iris._fetch('settings/'),
      branding: () => iris._fetch('settings/branding'),
      // get: id => iris._request(`settings/${id}`),
    },
    sfa_tasks: sfaApiV2.createResource('sfa_tasks', (req, resourcePath, apiFetch) => {
      return {
        display_list: query => apiFetch(`${resourcePath}/`, { query }),
        get: id => apiFetch(`${resourcePath}/${id}`),
        create: (data, config) => req(`${resourcePath}/`, Method.PUT, data || {}, false, config),
        update: body => apiFetch(`${resourcePath}/`, { method: Method.PUT, body }),
        activate: id => apiFetch(`${resourcePath}/${id}/activate`, { method: Method.POST }),
        iterations: id => apiFetch(`${resourcePath}/${id}/iterations`),
        delete: id => apiFetch(`${resourcePath}/${id}`, { method: Method.DELETE }),
        import: (formData) => {
          formData.append('import_type', 'sfa_tasks');
          return clio._request('imports/from_file', Method.POST, formData);
        },
      };
    }),
    sfa_tasks_general: {
      import: (formData) => {
        formData.append('import_type', 'sfa_tasks_v2');
        return clio._request('imports/from_file', Method.POST, formData);
      },
    },
    sfa_templates: sfaApi.createResource('sfa_templates', (req, resourcePath, apiFetch) => ({
      display_list: query => apiFetch(`${resourcePath}_display/`, { query }),
      duplicates: (id, query) => apiFetch(`${resourcePath}/${id}/duplicates`, { query }),
      tasksAmountById: id => apiFetch(`${resourcePath}/${id}/tasks_amount`),
      tasksAmount: data => req(`${resourcePath}/tasks_amount`, Method.POST, data),
      get: id => apiFetch(`${resourcePath}_display/${id}`),
      create: (data, config) => req(`${resourcePath}/`, Method.POST, data || {}, false, config),
      update: (id, data) => req(`${resourcePath}/${id}`, Method.PUT, data),
      activate: id => req(`${resourcePath}/${id}/activate`, Method.POST),
      deactivate: id => req(`${resourcePath}/${id}/deactivate`, Method.POST),
      delete: id => apiFetch(`${resourcePath}/${id}`, { method: Method.DELETE }),
      generate_tasks: (id, data) => req(`${resourcePath}/${id}/generate`, Method.POST, data || {}),
    })),
    planograms: muse.createResource('planograms'),
    posms: muse.createResource('posm'),
    mass_recount: visa.createResource('process/recalc_tasks'),
    photo_stream: iris.createResource('photo_stream', (req, resourcePath) => ({
      search: data => req(`${resourcePath}/search`, Method.POST, data),
    })),
  };
};
