
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as spajmEL4DWwefA0GvqGQtzNAU8GMBdvHbcZNkeqpsp3NWMMeta } from "/app/pages/spa.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutUcooZuBFq6Y_45qkSHe3Slgw7Ozl4rsMv5kY96MDZ8icoMeta } from "/app/pages/logout.vue?macro=true";
import { default as _91id_935_5G2ieiOiVsO5Ic7l_w0hIdGhEsgEbdH04xnHXtdvAMeta } from "/app/pages/areas/[id].vue?macro=true";
import { default as _91id_93ewWOF5nxuu1TbEArDQuVt7SEgBmNttEKyfuFTEE_vS8Meta } from "/app/pages/tasks/[id].vue?macro=true";
import { default as indexW2TsEJIZZ4dIUgEo3S4DdttB6Bk9KUlNTE8AMq4WGMYMeta } from "/app/pages/areas/index.vue?macro=true";
import { default as _91id_93MOpIBGSklMB4peGcVSrp84TSYMiaVusz8WszN1_45iKTIMeta } from "/app/pages/stores/[id].vue?macro=true";
import { default as indexWDRknbic_45ldGPof626xeyoNcmW9RyR3rCTrEBQrhEKkMeta } from "/app/pages/tasks/index.vue?macro=true";
import { default as indexwqqxPBLNl0Gqxy2G7X4gCBzRids0_eE4yk9P0pmntIkMeta } from "/app/pages/users/index.vue?macro=true";
import { default as _91id_93amnBpYxgbrkiksmNTjcqUcdtCAZvQ_iew_uXCzdcbDwMeta } from "/app/pages/visits/[id].vue?macro=true";
import { default as createSrVuIGnNHs07tAh3ZfVgDo2At9ubh0lEVCtrbA_45lBKEMeta } from "/app/pages/areas/create.vue?macro=true";
import { default as index_LJrHsPbuv2_DzKUjO6NHt_wORq_w3mqeGqzDMgkezQMeta } from "/app/pages/cities/index.vue?macro=true";
import { default as indexz79zrAz5sJZv_UjxXsucELQ7HssSuhXe3kB0NS1UtBEMeta } from "/app/pages/photos/index.vue?macro=true";
import { default as _91id_93W_tTsHD8zZsr_45M946uVRZge0P1ukyGHI_45wdCn7XfRYQMeta } from "/app/pages/reports/[id].vue?macro=true";
import { default as index3G8bR8ZtPPnBSbfSahGeBNM_45fR001JTUT1p5dGhYqlkMeta } from "/app/pages/routes/index.vue?macro=true";
import { default as indexjFmzYCPIk6f4UlvnZvPTtviub1WyjSyUq0Na3e9mLbMMeta } from "/app/pages/stores/index.vue?macro=true";
import { default as createvsfHJQGwJ_45Uj13_45XMqNe78ff4mBx7LWCEUZYOUr00nsMeta } from "/app/pages/tasks/create.vue?macro=true";
import { default as createPORaMKDecIkFPuV0xp8sBG1mgLa3eILUPnGfeic7ZgUMeta } from "/app/pages/users/create.vue?macro=true";
import { default as index287ERHQyXHq7eX_45KIsj5hbh0ed8OEbRERjvpsSeFlWoMeta } from "/app/pages/visits/index.vue?macro=true";
import { default as indexfV2NttKOH0OATlitosin5ILZB1W7wFe9ZsGMBDvROk4Meta } from "/app/pages/billing/index.vue?macro=true";
import { default as createekJt5hPjux_E2pnBeHzPyl_cK_45FTjh5b24t67vSsNuMMeta } from "/app/pages/cities/create.vue?macro=true";
import { default as index4U118b7z1cTGWDCqriSSrtgqXse35wWvRpAt97b72pQMeta } from "/app/pages/imports/index.vue?macro=true";
import { default as indexuaLeG_35j2xRAJPk_454Sk_45qhy9oQQ0zQOCB_4592rxEiVQMeta } from "/app/pages/metrics/index.vue?macro=true";
import { default as indexetDw_LpIy5siNS6Fn0ycnQZ6uco_FhpRlwsDI_m_453ZIMeta } from "/app/pages/periods/index.vue?macro=true";
import { default as indexvDb4l8D5y0rLlvbTWbOEaMQrIpkj_DtRiwcsRiWS3G0Meta } from "/app/pages/regions/index.vue?macro=true";
import { default as indexP_T7wPh_aodPu5ikFWVi4tb5ggCS9bYgYggORuX_45lvAMeta } from "/app/pages/reports/index.vue?macro=true";
import { default as createYXfFDUNcy2yooqEA9RHb7kVmORl6LNOrh8mqoV1ne6kMeta } from "/app/pages/stores/create.vue?macro=true";
import { default as create6f0gKj3_69dCgBMulQZvNw3almXx_452Z_BKvK7NhiIsIMeta } from "/app/pages/metrics/create.vue?macro=true";
import { default as createhULEC1Ul2LS23sfapFS5XDz0dETCskLZp4myUOGtKXEMeta } from "/app/pages/periods/create.vue?macro=true";
import { default as createoB9JGln8zzYeBCQK5wDsgkjpUlmPQBlG9ZwHFcyocaUMeta } from "/app/pages/regions/create.vue?macro=true";
import { default as indexhdIU_45rVXIRaVsU0zT4B2D70lbne0stp3lxancDpANbMMeta } from "/app/pages/segments/index.vue?macro=true";
import { default as indexakzzHpRM6q4L_XUQ8QAY4sVhvTGFNoVWfDqCL_45JdZrwMeta } from "/app/pages/countries/index.vue?macro=true";
import { default as _91id_93EhrpJMHgSeyqrF6eYS_45Y4AjWTuWkGeaR_45qZUrH_a5rcMeta } from "/app/pages/dashboards/[id].vue?macro=true";
import { default as password_45update7nLE0eX0RlxkAUbjWHziM5h5ECPZ6eGxew_458bHhKRVMMeta } from "/app/pages/password-update.vue?macro=true";
import { default as indexHLPyesnAK_Oo_4550_45QikR8rKhy_8bOVdyhJZsQQZYRtYMeta } from "/app/pages/retailers/index.vue?macro=true";
import { default as createta5km6DZaTsu4Hhf6to5UM2pLteZhJbAxN_45434W_456SgMeta } from "/app/pages/segments/create.vue?macro=true";
import { default as mobileHMSjUzjCSkWEBXVVK4EGSa4n_7fAHnvHchwkOna9G0AMeta } from "/app/pages/settings/mobile.vue?macro=true";
import { default as indexVC_1xWmanzubMHFSy9PMogQyB1cIw4szXXnFYNb56c0Meta } from "/app/pages/sfa-tasks/index.vue?macro=true";
import { default as createFOaF7i0zTEsaUExXBmMez89kM5129WiQDWBXTWoTm3wMeta } from "/app/pages/countries/create.vue?macro=true";
import { default as index4iNjgnOo47cMMHI_45OissuZP8Xw8M3blFLHQzYCgPHGYMeta } from "/app/pages/dashboards/index.vue?macro=true";
import { default as indexJccpZCMhAifUc9DrGZuz0q8OUjO_nl6WmYg4IfnE008Meta } from "/app/pages/planogramm/index.vue?macro=true";
import { default as createXowRZpUg0dRHGOK7uzT23T5mP7PTQb4cLZ3t0XZFT3MMeta } from "/app/pages/retailers/create.vue?macro=true";
import { default as createCAk1bXETns7XdtYvxYPGma9gctYKWZ5D7UOdjjDu6nQMeta } from "/app/pages/sfa-tasks/create.vue?macro=true";
import { default as password_45recoveryxotdI1Ei_wxzu9EOgj3yEBFgHcz_45OFLVepxk2gcChrMMeta } from "/app/pages/password-recovery.vue?macro=true";
import { default as indexYAk7dwU3Q13rmEGxIgDbTEAjFvE3lzWxfsRroLSDbyMMeta } from "/app/pages/mass-recount/index.vue?macro=true";
import { default as indexCE6fEs4Uj4bUwvqop9vQXthP4mGVocRVXJFsYrhC9mQMeta } from "/app/pages/metrics/[id]/index.vue?macro=true";
import { default as indexB_45DvqhWpc_45pQjYZhWP1eHAQ6YVofU6_45USxttLtGXB_454Meta } from "/app/pages/scene-groups/index.vue?macro=true";
import { default as templateGH_CSbTSObBszneV1HwqGpYZ9hSKC237zMZ4Xkh3xaQMeta } from "/app/pages/sfa-tasks/template.vue?macro=true";
import { default as create1BoBcaYAuiHfm1ydKUO45n_vcwQmnmJpWrFlVVSzCXcMeta } from "/app/pages/mass-recount/create.vue?macro=true";
import { default as createqIeroNMvRuoDWT4_459cA_45BoWDoGRsizaOHbDFagzS1CIMeta } from "/app/pages/scene-groups/create.vue?macro=true";
import { default as _91action_93ebnwb2UxgGFys34ioMyIz82kbSSiWwjf8XRKq9E5_YEMeta } from "/app/pages/metrics/[id]/[action].vue?macro=true";
import { default as index36LXPcW7JCqD6aWu3sLcz_vSZcT3o0dibKTbUWR8XYAMeta } from "/app/pages/planned-metrics/index.vue?macro=true";
import { default as index4hPTg3Vul1jfKrvcNyylYVRFSkINJrgXLxuTfCccmS4Meta } from "/app/pages/price-standards/index.vue?macro=true";
import { default as _91id_93RE6rHTf5ZVeDVG1HpESgdNknVJl1JQn9NWOVK_OHGnkMeta } from "/app/pages/reports/download/[id].vue?macro=true";
import { default as indexICyP7wjRz4JjrlHqSUlJtSJsOo3p3hyLVeAudrPo1R4Meta } from "/app/pages/sales-locations/index.vue?macro=true";
import { default as createrROnX9ww6HFtfSd5ejWDk2TCA_45YyBuWJ1BY_45bI1vv8YMeta } from "/app/pages/planned-metrics/create.vue?macro=true";
import { default as index6BYO_45FbOjadkcV3TfwPLXm29LzxX1qoFYHBsZ8LtG8QMeta } from "/app/pages/plans/assortment/index.vue?macro=true";
import { default as createW_wPbN_45_r_456It1gYj4vO_45cLM0uPLtdRsVE9TILZnFLMMeta } from "/app/pages/price-standards/create.vue?macro=true";
import { default as createHcQPXHyHNvdX5B9UvREmBQR50yQN8MIxVw2nh_45cc6BAMeta } from "/app/pages/sales-locations/create.vue?macro=true";
import { default as createEDuQuX3tesZduC7S9X2uD8HuzmvCTuEIZYvGpRtlWiYMeta } from "/app/pages/plans/assortment/create.vue?macro=true";
import { default as indexC0aIQq5rc2ZdENxtnJO3jCqHoz2Gn7khyTd9E7kc5M4Meta } from "/app/pages/equipment-and-posm/index.vue?macro=true";
import { default as index26_9FLd2n99zItb1pMYKVHmAHdnhWfbXTpQlrBjMVE0Meta } from "/app/pages/plans/assortment-type/index.vue?macro=true";
import { default as indexnja2g93JUh5asTp8qiSsfWpYUUlklwfGxHGBHNJ44uIMeta } from "/app/pages/plans/assortment/[id]/index.vue?macro=true";
import { default as create8i8hOn1OisqyO3roY32oKhO_45DRLEBdlfsBvPrEhAUZcMeta } from "/app/pages/plans/assortment-type/create.vue?macro=true";
import { default as _91plan_id_93zTL_rXKL91m_45Il_455g41C6pTj8lAdg8cC7_455yVqfr97YMeta } from "/app/pages/plans/assortment/[id]/[plan_id].vue?macro=true";
export default [
  {
    name: "SpaSettings",
    path: "/spa",
    meta: spajmEL4DWwefA0GvqGQtzNAU8GMBdvHbcZNkeqpsp3NWMMeta || {},
    component: () => import("/app/pages/spa.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "LoginPage",
    path: "/login",
    meta: loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "LogoutPage",
    path: "/logout",
    meta: logoutUcooZuBFq6Y_45qkSHe3Slgw7Ozl4rsMv5kY96MDZ8icoMeta || {},
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "areas-id",
    path: "/areas/:id()",
    component: () => import("/app/pages/areas/[id].vue")
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    component: () => import("/app/pages/tasks/[id].vue")
  },
  {
    name: "areas",
    path: "/areas",
    component: () => import("/app/pages/areas/index.vue")
  },
  {
    name: "stores-id",
    path: "/stores/:id()",
    component: () => import("/app/pages/stores/[id].vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/app/pages/tasks/index.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/app/pages/users/index.vue")
  },
  {
    name: "visits-id",
    path: "/visits/:id()",
    component: () => import("/app/pages/visits/[id].vue")
  },
  {
    name: "CreateArea",
    path: "/areas/create",
    meta: createSrVuIGnNHs07tAh3ZfVgDo2At9ubh0lEVCtrbA_45lBKEMeta || {},
    component: () => import("/app/pages/areas/create.vue")
  },
  {
    name: "CitiesPage",
    path: "/cities",
    meta: index_LJrHsPbuv2_DzKUjO6NHt_wORq_w3mqeGqzDMgkezQMeta || {},
    component: () => import("/app/pages/cities/index.vue")
  },
  {
    name: "photos",
    path: "/photos",
    component: () => import("/app/pages/photos/index.vue")
  },
  {
    name: "reports-id",
    path: "/reports/:id()",
    component: () => import("/app/pages/reports/[id].vue")
  },
  {
    name: "routes",
    path: "/routes",
    component: () => import("/app/pages/routes/index.vue")
  },
  {
    name: "stores",
    path: "/stores",
    component: () => import("/app/pages/stores/index.vue")
  },
  {
    name: "tasks-create",
    path: "/tasks/create",
    component: () => import("/app/pages/tasks/create.vue")
  },
  {
    name: "CreateUser",
    path: "/users/create",
    meta: createPORaMKDecIkFPuV0xp8sBG1mgLa3eILUPnGfeic7ZgUMeta || {},
    component: () => import("/app/pages/users/create.vue")
  },
  {
    name: "visits",
    path: "/visits",
    component: () => import("/app/pages/visits/index.vue")
  },
  {
    name: "billing",
    path: "/billing",
    component: () => import("/app/pages/billing/index.vue")
  },
  {
    name: "CreateCity",
    path: "/cities/create",
    meta: createekJt5hPjux_E2pnBeHzPyl_cK_45FTjh5b24t67vSsNuMMeta || {},
    component: () => import("/app/pages/cities/create.vue")
  },
  {
    name: "imports",
    path: "/imports",
    component: () => import("/app/pages/imports/index.vue")
  },
  {
    name: "metrics",
    path: "/metrics",
    component: () => import("/app/pages/metrics/index.vue")
  },
  {
    name: "periods",
    path: "/periods",
    component: () => import("/app/pages/periods/index.vue")
  },
  {
    name: "regions",
    path: "/regions",
    component: () => import("/app/pages/regions/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: indexP_T7wPh_aodPu5ikFWVi4tb5ggCS9bYgYggORuX_45lvAMeta || {},
    component: () => import("/app/pages/reports/index.vue")
  },
  {
    name: "CreateStore",
    path: "/stores/create",
    meta: createYXfFDUNcy2yooqEA9RHb7kVmORl6LNOrh8mqoV1ne6kMeta || {},
    component: () => import("/app/pages/stores/create.vue")
  },
  {
    name: "metrics-create",
    path: "/metrics/create",
    component: () => import("/app/pages/metrics/create.vue")
  },
  {
    name: "CreatePeriod",
    path: "/periods/create",
    meta: createhULEC1Ul2LS23sfapFS5XDz0dETCskLZp4myUOGtKXEMeta || {},
    component: () => import("/app/pages/periods/create.vue")
  },
  {
    name: "CreateRegion",
    path: "/regions/create",
    meta: createoB9JGln8zzYeBCQK5wDsgkjpUlmPQBlG9ZwHFcyocaUMeta || {},
    component: () => import("/app/pages/regions/create.vue")
  },
  {
    name: "segments",
    path: "/segments",
    component: () => import("/app/pages/segments/index.vue")
  },
  {
    name: "countries",
    path: "/countries",
    component: () => import("/app/pages/countries/index.vue")
  },
  {
    name: "dashboards-id",
    path: "/dashboards/:id()",
    component: () => import("/app/pages/dashboards/[id].vue")
  },
  {
    name: "PasswordRecoveryPage",
    path: "/password-update",
    meta: password_45update7nLE0eX0RlxkAUbjWHziM5h5ECPZ6eGxew_458bHhKRVMMeta || {},
    component: () => import("/app/pages/password-update.vue")
  },
  {
    name: "retailers",
    path: "/retailers",
    component: () => import("/app/pages/retailers/index.vue")
  },
  {
    name: "CreateSegment",
    path: "/segments/create",
    meta: createta5km6DZaTsu4Hhf6to5UM2pLteZhJbAxN_45434W_456SgMeta || {},
    component: () => import("/app/pages/segments/create.vue")
  },
  {
    name: "settings-mobile",
    path: "/settings/mobile",
    component: () => import("/app/pages/settings/mobile.vue")
  },
  {
    name: "sfa-tasks",
    path: "/sfa-tasks",
    component: () => import("/app/pages/sfa-tasks/index.vue")
  },
  {
    name: "CreateCountry",
    path: "/countries/create",
    meta: createFOaF7i0zTEsaUExXBmMez89kM5129WiQDWBXTWoTm3wMeta || {},
    component: () => import("/app/pages/countries/create.vue")
  },
  {
    name: "dashboards",
    path: "/dashboards",
    component: () => import("/app/pages/dashboards/index.vue")
  },
  {
    name: "planogramm",
    path: "/planogramm",
    component: () => import("/app/pages/planogramm/index.vue")
  },
  {
    name: "CreateRetailer",
    path: "/retailers/create",
    meta: createXowRZpUg0dRHGOK7uzT23T5mP7PTQb4cLZ3t0XZFT3MMeta || {},
    component: () => import("/app/pages/retailers/create.vue")
  },
  {
    name: "sfa-tasks-create",
    path: "/sfa-tasks/create",
    component: () => import("/app/pages/sfa-tasks/create.vue")
  },
  {
    name: "PasswordRecoveryPage",
    path: "/password-recovery",
    meta: password_45recoveryxotdI1Ei_wxzu9EOgj3yEBFgHcz_45OFLVepxk2gcChrMMeta || {},
    component: () => import("/app/pages/password-recovery.vue")
  },
  {
    name: "mass-recount",
    path: "/mass-recount",
    component: () => import("/app/pages/mass-recount/index.vue")
  },
  {
    name: "metrics-id",
    path: "/metrics/:id()",
    component: () => import("/app/pages/metrics/[id]/index.vue")
  },
  {
    name: "scene-groups",
    path: "/scene-groups",
    component: () => import("/app/pages/scene-groups/index.vue")
  },
  {
    name: "sfa-tasks-template",
    path: "/sfa-tasks/template",
    component: () => import("/app/pages/sfa-tasks/template.vue")
  },
  {
    name: "mass-recount-create",
    path: "/mass-recount/create",
    component: () => import("/app/pages/mass-recount/create.vue")
  },
  {
    name: "CreateSceneGroup",
    path: "/scene-groups/create",
    meta: createqIeroNMvRuoDWT4_459cA_45BoWDoGRsizaOHbDFagzS1CIMeta || {},
    component: () => import("/app/pages/scene-groups/create.vue")
  },
  {
    name: "metrics-id-action",
    path: "/metrics/:id()/:action()",
    component: () => import("/app/pages/metrics/[id]/[action].vue")
  },
  {
    name: "PlannedMetricsPage",
    path: "/planned-metrics",
    meta: index36LXPcW7JCqD6aWu3sLcz_vSZcT3o0dibKTbUWR8XYAMeta || {},
    component: () => import("/app/pages/planned-metrics/index.vue")
  },
  {
    name: "price-standards",
    path: "/price-standards",
    component: () => import("/app/pages/price-standards/index.vue")
  },
  {
    name: "reports-download-id",
    path: "/reports/download/:id()",
    component: () => import("/app/pages/reports/download/[id].vue")
  },
  {
    name: "sales-locations",
    path: "/sales-locations",
    component: () => import("/app/pages/sales-locations/index.vue")
  },
  {
    name: "CreatePlannedMetrics",
    path: "/planned-metrics/create",
    meta: createrROnX9ww6HFtfSd5ejWDk2TCA_45YyBuWJ1BY_45bI1vv8YMeta || {},
    component: () => import("/app/pages/planned-metrics/create.vue")
  },
  {
    name: "plans-assortment",
    path: "/plans/assortment",
    component: () => import("/app/pages/plans/assortment/index.vue")
  },
  {
    name: "CreatePriceStandard",
    path: "/price-standards/create",
    meta: createW_wPbN_45_r_456It1gYj4vO_45cLM0uPLtdRsVE9TILZnFLMMeta || {},
    component: () => import("/app/pages/price-standards/create.vue")
  },
  {
    name: "CreateSalesLocation",
    path: "/sales-locations/create",
    meta: createHcQPXHyHNvdX5B9UvREmBQR50yQN8MIxVw2nh_45cc6BAMeta || {},
    component: () => import("/app/pages/sales-locations/create.vue")
  },
  {
    name: "CreateAssortment",
    path: "/plans/assortment/create",
    meta: createEDuQuX3tesZduC7S9X2uD8HuzmvCTuEIZYvGpRtlWiYMeta || {},
    component: () => import("/app/pages/plans/assortment/create.vue")
  },
  {
    name: "equipment-and-posm",
    path: "/equipment-and-posm",
    component: () => import("/app/pages/equipment-and-posm/index.vue")
  },
  {
    name: "plans-assortment-type",
    path: "/plans/assortment-type",
    component: () => import("/app/pages/plans/assortment-type/index.vue")
  },
  {
    name: "plans-assortment-id",
    path: "/plans/assortment/:id()",
    component: () => import("/app/pages/plans/assortment/[id]/index.vue")
  },
  {
    name: "CreateAssortmentType",
    path: "/plans/assortment-type/create",
    meta: create8i8hOn1OisqyO3roY32oKhO_45DRLEBdlfsBvPrEhAUZcMeta || {},
    component: () => import("/app/pages/plans/assortment-type/create.vue")
  },
  {
    name: "plans-assortment-id-plan_id",
    path: "/plans/assortment/:id()/:plan_id()",
    component: () => import("/app/pages/plans/assortment/[id]/[plan_id].vue")
  }
]