import { stringify } from 'qs';

import { createFetchClient } from '~/assets/utils/useFetch';

export enum Method {
  POST = 'post',
  GET = 'get',
  DELETE = 'delete',
  PUT = 'put',
  HEAD = 'head',
}

export enum ParseMethod {
  JSON = 'json',
  TEXT = 'text',
  BLOB = 'blob',
}

function trimSlashes(path: string) {
  return path.replace(/(^\/+|\/+$)/g, '');
}

function getRequestQuery(params: object) {
  return stringify(params, {
    arrayFormat: 'repeat',
    addQueryPrefix: true,
    filter: (_prefix, value) => isNullish(value) ? undefined : value
  });
}

function addBody(fetchConfig, body) {
  if (body.constructor?.name !== 'FormData') {
    fetchConfig.body = JSON.stringify(body);
    fetchConfig.headers = {
      ...(fetchConfig.headers || {}),
      'Content-Type': 'application/json',
    };
  } else {
    fetchConfig.body = body;
  }
}

function isRequestNeedAuth(url: string) {
  if (url.includes('settings/branding')) return false;
  if (url.includes('auth/configuration')) return false;
  return true;
}

export default function useApiClient(baseURL: string) {
  const userStore = useUserStore();

  const apiFetch = $fetch.create({
    baseURL,
    async onRequest({ request: url, options }) {
      if (userStore.loggedIn && isRequestNeedAuth(url)) {
        await userStore.updateTokenSSO();

        const token = userStore.getUserToken();
        if (token) options.headers.set('Authorization', token);
      }
    },
    onResponseError(ctx) {
      if (ctx.response?.status === 401 || ctx.response._data?.message === 'Not authorized') {
        useUserStore().relogin();
        return;
      }

      if (ctx.response?.status === 204) {
        ctx.error = null;
        return;
      }

      if (ctx.response?.status === 500) {
        ctx.error = ctx.response.statusText;
        return;
      }

      ctx.error = ctx.response?._data?.message || ctx.error;

      useAppStore().setLoading(false);
      console.error(ctx.error);
      // logError(context);
    },
    onRequestError(context) {
      console.error(JSON.stringify(context));
      context.error = context.error?.message;
      // logError(context);
    },
  })

  const fetchClient = createFetchClient(baseURL);

  async function request(url, method = Method.GET, body = null, query = {}, config = {}, parseMethod = ParseMethod.JSON) {
    if (query) url += getRequestQuery(query);

    const fetchConfig = {
      method,
      ...config,
    };

    if (body) addBody(fetchConfig, body);

    const { data, error } = await fetchClient(url, fetchConfig)[parseMethod]();

    if (error.value) {
      throw new Error(data.value?.message);
    }

    return data.value;
  }

  function createResource(name: string, customMethodsFactory: (req: typeof request, resPath?: string, _apiFetch: typeof apiFetch) => {
    [key: string]: (...args: any[]) => ReturnType<typeof request> | ReturnType<typeof apiFetch>
  } = undefined) {
    const resourcePath = trimSlashes(name);
    const create = (body = {}) => apiFetch(`${resourcePath}/`, { method: Method.POST, body });
    const list = query => apiFetch(`${resourcePath}/`, { query });
    const get = id => apiFetch(`${resourcePath}/${id}`);
    const update = (id, body) => apiFetch(`${resourcePath}/${id}`, { method: Method.PUT, body });
    const deleteMethod = id => apiFetch(`${resourcePath}/${id}`, { method: Method.DELETE });
    const search = query => apiFetch(`${resourcePath}/search`, { query });
    const suggest = query => apiFetch(`${resourcePath}/suggest`, { query });
    const importMethod = body => apiFetch(`${resourcePath}/import`, { method: Method.POST, body });
    const importPreview = body => apiFetch(`${resourcePath}/import/preview`, { method: Method.POST, body });
    const display = (id, query) => apiFetch(`${resourcePath}/${id}/display`, { query });
    const display_list = query => apiFetch(`${resourcePath}/display`, { query });

    const customMethods = typeof customMethodsFactory === 'function'
      ? customMethodsFactory(request, resourcePath, apiFetch)
      : {};

    return {
      create,
      list,
      get,
      update,
      delete: deleteMethod,
      search,
      suggest,
      import: importMethod,
      importPreview,
      display,
      display_list,
      ...customMethods,
    };
  }

  return {
    baseUrl: baseURL,
    createResource,
    _fetch: apiFetch,
    _request: request,
  };
}
