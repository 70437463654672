import { useApi } from '~/plugins/api';

const additional = {
  awaitSettings: null,
  awaitBranding: null,
};

export const HideOSAFilter = {
  None: 'none',
  OutOfAssortmentMatrix: 'out_of_assortment_matrix',
} as const;

export const MetricsService = {
  None: 'none',
  Google: 'google',
  Yandex: 'yandex',
} as const;

interface Branding {
  logo: string;
  logoFull: string;
  slogan: string;
}

export const useSettingsStore = defineStore('portalSettings', () => {
  /** Branding */
  const { branding, clean } = usePortalBranding();
  const isBrandingInitiated = ref(false);

  const { showInfo } = useTableStore();

  const metricsService = ref<typeof MetricsService[keyof typeof MetricsService]>(MetricsService.None);

  function setBranding(settings) {
    isBrandingInitiated.value = true;
    branding.logo = settings.logo_url;
    branding.logoFull = settings.logo_full_url;
    branding.slogan = settings.slogan;

    metricsService.value = settings.metrics_service || MetricsService.None;
  }

  function cleanBranding() {
    if (!isBrandingInitiated.value) {
      clean();
    }
  }

  function initBranding(force = false) {
    if (additional.awaitBranding) return;

    cleanBranding();
    if (force || !isBrandingInitiated) {
      additional.awaitBranding = useApi().portal_settings.branding().then(setBranding);
    }
  }

  /** Branding */

  const showRoutes = ref(false);
  const enableAreas = ref(false);
  const showSfaTasks = ref(false);
  const showPlanogramm = ref(false);

  const sfaTasksCustomImport = ref(false);

  const appSearchPhoto = ref(false);
  const globalFacingCalcGroup = ref(false);
  const maxReportPeriod = ref<number | null>(null);
  const widgets = ref<string[]>([]);

  const hideOSAFilter = ref<typeof HideOSAFilter[keyof typeof HideOSAFilter]>(HideOSAFilter.None);
  const showBrandOwnerBoxesOnly = ref(false);

  const stitching = ref(false);

  const sync = reactive({
    [PagesNames.Stores]: false,
    [PagesNames.Users]: false,
    [PagesNames.Products]: false,
    [PagesNames.AssortmentMatrices]: false,
  });
  const hideSync = reactive({
    [PagesNames.Stores]: false,
    [PagesNames.Users]: false,
    [PagesNames.Products]: false,
    [PagesNames.AssortmentMatrices]: false,
  });

  const correction = reactive({
    show: false,
    type: '',
  });

  function initSettings() {
    additional.awaitSettings = useApi().portal_settings.list();
    return additional.awaitSettings.then((settings) => {
      setBranding(settings);

      showRoutes.value = Boolean(settings.turn_routes_on);
      showSfaTasks.value = Boolean(settings.enable_sfa_tasks);
      showPlanogramm.value = Boolean(settings.calc_planogramm);

      sfaTasksCustomImport.value = Boolean(settings.sfa_tasks_custom_import);
      enableAreas.value = Boolean(settings.is_areas_enabled);
      appSearchPhoto.value = Boolean(settings.search_indexes?.photo);
      globalFacingCalcGroup.value = Boolean(settings.global_facing_calc_group);
      maxReportPeriod.value = settings.max_report_period || null;
      widgets.value = settings.portal_interface_settings || [];

      hideOSAFilter.value = settings.hide_OSA_filter || HideOSAFilter.None;
      showBrandOwnerBoxesOnly.value = settings.show_brandowner_boxes_only || false;
      // sync
      const automaticSync = settings.automatic_sync || {};
      sync[PagesNames.Stores] = Boolean(automaticSync.stores);
      sync[PagesNames.Users] = Boolean(automaticSync.users);
      sync[PagesNames.Products] = Boolean(automaticSync.products);
      sync[PagesNames.AssortmentMatrices] = Boolean(automaticSync['planed assortment']);

      // correction
      correction.show = Boolean(settings.show_correction);
      correction.type = settings.correction_type_on_realogram || '';

      stitching.value = settings.algorithms_stitching || false;
    }).catch(initBranding);
  }

  return {
    branding,
    metricsService,
    showRoutes,
    enableAreas,
    showSfaTasks,
    showPlanogramm,
    appSearchPhoto,
    globalFacingCalcGroup,
    maxReportPeriod,
    widgets,
    sync,
    hideSync,
    hideOSAFilter,
    showBrandOwnerBoxesOnly,
    correction,
    isBrandingInitiated,
    stitching,
    sfaTasksCustomImport,
    initBranding,
    initSettings,
    showInfo,
  };
}, {
  persist: {
    storage: localStorage,
  }
});

export { additional };

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot))
}
