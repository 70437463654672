import type { IBreadcrumb } from '@frontend/ui-kit/dist/runtime/utils/types';

function useLoaderMessage() {
  const loadingMessage = ref<string | any[]>('');

  function setLoadingMessage(message: any) {
    loadingMessage.value = message;
  }

  function resetLoadingMessage() {
    loadingMessage.value = '';
  }

  return {
    loadingMessage,
    setLoadingMessage,
    resetLoadingMessage,
  };
}

export interface FullPageOption {
  mobile: boolean;
  desktop: boolean
}

export const useAppStore = defineStore('app', () => {
  const loading = ref(false);
  const fixedHeader = ref(false);
  const fullPage = ref<FullPageOption>({ desktop: false, mobile: false });
  const title = ref('Ailet');
  const defaultPadding = ref(true);
  const backLink = ref('');
  const breadcrumbs = ref<IBreadcrumb[]>([]);
  const titleShadow = ref(false);
  const darkBackground = ref(false);
  const headerTeleportId = ref('');
  const mobileHeaderBorder = ref(false);

  function setLoading(value: boolean) {
    loading.value = value;
  }

  const recentPages = useRecentPagesStore();

  function setTitle(value: string) {
    title.value = value;
    recentPages.updateTitle(value);
  }

  function changeBreadcrumb(payload: { pos: number, element: IBreadcrumb }) {
    if (breadcrumbs.value[payload.pos]) {
      breadcrumbs.value[payload.pos] = payload.element;
      breadcrumbs.value = [...breadcrumbs.value];
    }
  }

  const { loadingMessage, setLoadingMessage, resetLoadingMessage } = useLoaderMessage();
  const isLoading = computed(() => Boolean(loading.value || loadingMessage.value));

  return {
    loadingMessage,
    setLoadingMessage,
    resetLoadingMessage,
    loading,
    isLoading,
    fixedHeader,
    fullPage,
    title,
    defaultPadding,
    backLink,
    breadcrumbs,
    titleShadow,
    darkBackground,
    headerTeleportId,
    setLoading,
    setTitle,
    changeBreadcrumb,
    mobileHeaderBorder,
  };
}, {
  persist: {
    storage: localStorage,
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
