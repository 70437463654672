import { useCookies } from '@vueuse/integrations/useCookies';

const cookies = useCookies(['locale']);

// Иначе не видит token
export function getCookie(name) {
  const cookie = {};
  document.cookie.split(';').forEach(function (el) {
    const [k, v] = el.split('=');
    cookie[k.trim()] = v;
  });
  return cookie[name];
}

export function setCookie(name: string, value: any) {
  cookies.set(name, value, { path: '/' });
}

export function removeCookie(name: string) {
  cookies.remove(name);
}
