import { watchOnce } from '@vueuse/core';
import VueYandex from 'vue3-yandex-metrika';

import { YandexMetricConfig, GoogleMetricConfig } from '~/configs/metricsConfig';
import { MetricsService } from '~/stores/settingsStore';

export default defineNuxtPlugin((nuxtApp) => {
  const { initialize: initializeGtag, gtag } = useGtag();
  const router = useRouter();

  const metricsHandler = {
    [MetricsService.Yandex]: () => {
      nuxtApp.vueApp.use(VueYandex, {
        ...YandexMetricConfig,
        env: process.env.NODE_ENV,
        router,
      });
    },
    [MetricsService.Google]: () => {
      if (process.env.NODE_ENV !== 'development') initializeGtag(GoogleMetricConfig.config.id);
    },
  };

  const store = useSettingsStore();

  if (store.metricsService !== MetricsService.None) {
      metricsHandler[store.metricsService]();
  } else {
      watchOnce(() => store.metricsService, () => {
          if (store.metricsService !== MetricsService.None) {
              metricsHandler[store.metricsService]();
          }
      });
  }
});
